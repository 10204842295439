import React from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import { color } from '@configs/utilities'
import Layout from '@components/layouts/default/Layout'
import HeroSectionV5 from '@sections/home/HeroSectionV5'
import BenefitsSection from '@sections/home/BenefitsSection'
import AdvertisementSection from '@sections/common/AdvertisementSection'
import TestimonialsSection from '@sections/common/TestimonialsSection'
import FeaturesSection from '@sections/home/FeaturesSection'
import TemplatesSection from '@sections/home/TemplatesSection'
import ReadySection from '@sections/common/ReadySection'
import AiAssistanceSection from '@sections/home/AIAssistanceSection'
import TrustedBySection from '@sections/home/TrustedBySection'
const Home = ({ intl }) => {
  return (
    <Layout pageName="home2">
      <HeroSectionV5 />
      <TrustedBySection />
      <FeaturesSection open="idea-plan" />
      <AiAssistanceSection />
      <BenefitsSection />
      <AdvertisementSection
        componentBackground={color.blueDark}
        titleText={intl.formatMessage({ id: 'home.businessPlanning.title' })}
        subtitleText={intl.formatMessage({ id: 'home.businessPlanning.subtitle' })}
        to="/pricing"
        // external
        // href={generateAppLink('signup', intl.locale)}
        buttonBackground={color.yellow}
        buttonColor={color.white}
        buttonText={intl.formatMessage({ id: 'home.businessPlanning.button' })}
      />
      <TemplatesSection />
      <TestimonialsSection />
      <ReadySection
        sectionBackground={color.blueLight}
        title={intl.formatMessage({ id: 'home.readyToStartYourAdventure.title' })}
        description={intl.formatMessage({ id: 'home.readyToStartYourAdventure.description' })}
        buttonText={intl.formatMessage({ id: 'home.readyToStartYourAdventure.buttonText' })}
        to="/pricing"
        // external
        // href={generateAppLink('signup', intl.locale)}
        explain
        explainText={intl.formatMessage({ id: 'home.readyToStartYourAdventure.explainText' })}
      />
    </Layout>
  )
}

export default injectIntl(Home)
