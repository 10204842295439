import React from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import styled from 'styled-components'
import { color, breakpoint } from '@configs/utilities'
import SectionWrapper from '@components/ui/wrappers/SectionWrapper'
import ButtonLink from '@components/ui/buttons/ButtonLink'
import { DescriptionDisplay1 } from '@components/ui/typography/Typography'
// import UnderlineHeading from '@components/common/UnderlineHeading'
import { StaticImage } from 'gatsby-plugin-image'
import VideoTourButton from '@components/common/VideoTourButton'
import Excellent02 from '@components/pages/home/rating/Excellent02'
import TagRanked from '@components/common/TagRanked'
const HeroSectionV5 = ({ intl }) => {
  return (
    <SectionWrapper>
      <Wrapper>
        <Text>
          <TagRanked text={intl.formatMessage({ id: 'miscellaneous.ratedOne' })} margin="0 0 -10px 0" />
          <div className="headingWrapper">
            <HeadingH1>
              {intl.formatMessage({ id: 'home.hero.title.homepage2' })}
              {/*<UnderlineHeading*/}
              {/*  text={intl.formatMessage({ id: 'home.hero.title.allInOne' })}*/}
              {/*  display="block"*/}
              {/*  mdDisplay="inline-block"*/}
              {/*  xlDisplay="inline-block"*/}
              {/*  mdMargin="0"*/}
              {/*  height="48px"*/}
              {/*  xlHeight="60px"*/}
              {/*/>*/}
              {/*&nbsp;*/}
              {/*{intl.formatMessage({ id: 'home.hero.title.businessPlanningSoftware' })}*/}
            </HeadingH1>
          </div>
          <div className="descriptionWrapper">
            <DescriptionDisplay1>{intl.formatMessage({ id: 'home.hero.homepage2Description' })}</DescriptionDisplay1>
          </div>
          <div className="buttonLinkWrapper">
            <div className="buttons">
              <ButtonLink
                to="/pricing"
                // external
                // href={generateAppLink('signup', intl.locale)}
                buttonBackground={color.blue}
                buttonColor={color.white}
                buttonText={intl.formatMessage({ id: 'home.hero.startFree' })}
                smfontsize={'16px'}
                mdfontsize={'16px'}
              />
              <VideoTourButton videoId="Mb0-CeTuzY8" smfontsize={'16px'} mdfontsize={'16px'} />
            </div>
            <p>{intl.formatMessage({ id: 'home.hero.try' })}</p>
          </div>
          <ExcellentWrapper>
            <Excellent02 />
          </ExcellentWrapper>
        </Text>
        <StaticImage src="../../assets/images/home/hero/heroImage9.webp" width={654} alt="Hero image" />
      </Wrapper>
    </SectionWrapper>
  )
}

export default injectIntl(HeroSectionV5)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${breakpoint.sm}) {
    align-items: center;
  }

  @media screen and (min-width: ${breakpoint.xl}) {
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
  }
`

const HeadingH1 = styled.h1`
  font-size: 38px;
  font-weight: 700;
  color: ${color.font};

  @media screen and (min-width: ${breakpoint.xl}) {
    font-size: 48px;
  }
`

const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 40px;

  @media screen and (min-width: ${breakpoint.xl}) {
    align-items: flex-start;
    max-width: 45%;
  }

  .headingWrapper {
    text-align: center;

    @media screen and (min-width: ${breakpoint.xl}) {
      text-align: left;
    }
  }

  .descriptionWrapper {
    max-width: 450px;
    text-align: center;

    @media screen and (min-width: ${breakpoint.md}) {
      max-width: 550px;
    }

    @media screen and (min-width: ${breakpoint.xl}) {
      text-align: left;
    }
  }

  .buttonLinkWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 12px;

    .buttons {
      display: flex;
      flex-direction: column;
      justify-content: center;
      row-gap: 12px;

      @media screen and (min-width: ${breakpoint.xl}) {
        padding-bottom: 0;
        flex-direction: row;
        column-gap: 12px;
        justify-content: flex-start;
      }
    }

    p {
      opacity: 0.4;
      font-size: 12px;
    }
  }
`

const ExcellentWrapper = styled.div`
  margin-bottom: 40px;

  @media screen and (min-width: ${breakpoint.xl}) {
    margin-bottom: 0;
  }
`
